html {
  overflow-y: scroll;
}

.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
  }

  .card-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
  }

  .card-container .card {
    flex: 1; /* Equal width for all cards */
  }

  .toast-container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1050; /* High z-index to keep it above other elements */
    margin: 20px;
  }
  
  /* LoadingScreen.css */
.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
}

.loading-circle {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-screen p {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}

.company-cell {
  display: flex;
  align-items: center;
}

.company-logo {
  height: 40px;
  width: 40px;
  margin-right: 10px; /* Adjust the margin as needed */
}

.company-details {
  display: flex;
  flex-direction: column;
}

.company-name {
  font-weight: bold;
  /* Add other styles like font-size, color, etc. */
}

.leaflet-marker-icon {
  border-radius: 50%;
  border: 1px solid darkblue;
}

.btn-status {
  color: white !important;
  width: 120px;
  text-align: center;
}

.totalTime {
  border: 2px solid black;
}

.big-borders {
  border: 2px solid rgb(33, 32, 32) !important;
  margin-bottom: 10px;
}

/* Styling for smaller screens (e.g., smartphones) */
@media (max-width: 576px) {
  .responsive-btn {
    padding: 5px; /* Reduced padding for smaller screens */
    font-size: 1.2rem; /* Adjust font size for better readability */
  }
}

@media (max-width: 1400px) {
  .btn-status {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 768px) {
  .btn-status {
    font-size: 0.6rem !important;
  }
}

.responsive-input {
  width: 250px;
}

@media (max-width: 1200px) {
  .responsive-input {
    width: 175px !important;
  }
}